export const parseProductOrder = (po) => {
  return {
    ...po,
    id: `card-${po.id}`,
    products_order_id: po.id,
    name: (po.custom_product_name || po.product_name),
    client_name: po.client_name,
    image: (po.first_custom_image || po.main_image || '/static/illustrations/illustration_empty_content.svg'),
    order_uuid: po.order_uuid,
    order_item: po.order_item,
    edited: po.order_item.edited,
    edited_user: po.order_item.edited_user,
    status_id: po.status,
    completed_requirements: po.completed_requirements,
    total_requirements: po.total_requirements,
    immediate_delivery: po.order_item.immediate_delivery,
    stock_company: po.order_item.stock_company
  }
}

export function parseOrderProductCards(products_order) {
  const data = products_order.map(po => (parseProductOrder(po)));
  return data;
}

export function moveCardsToColumns(cards, columns) {
  columns.forEach(status => {
    status.status_id = status.id;
    status.id = `column-${status.id}`;

    const columnCardIds = cards.filter(c => c.status === status.status_id).map(c => c.id);
    status.cardIds = [...(status.cardIds || []), ...columnCardIds].reverse();
  });
  return columns;
}
