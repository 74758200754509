import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import {alpha, styled} from '@mui/material/styles';
import {AppBar, Box, IconButton, Stack, Toolbar} from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import {MHidden} from '../../components/@material-extend';
// import Searchbar from './Searchbar';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: 'none',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({onOpenSidebar}) {
  const {isCollapse} = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: {lg: `calc(100% - ${COLLAPSE_WIDTH}px)`}
        })
      }}
    >

      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{
            mr: 1,
            color: 'text.primary',
            background: 'white',
            border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`
          }}>
            <Icon icon={menu2Fill}/>
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Box sx={{flexGrow: 1}}/>

        <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
          {/*<NotificationsPopover />*/}
          {/* <AccountPopover /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
